#cc-home-container {
  margin-top: 90px;
}

#cc-about-container {
  height: auto;
  background: rgba(0, 0, 0, 0.35);
  padding: 100px 30px 350px 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.cc-about-section {
  z-index: 1;
  flex: 1;
  /* max-width: calc(100vw - 60px); */
  max-width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px;
}

.cc-about-skew {
  transform: skewX(-6deg) skewY(-6deg);
  background-color: rgb(30, 30, 30);
  margin: 15px;
  opacity: 0.9;
}

#cc-about-details {
  transform: skewX(6deg) skewY(6deg);
  padding: 20px 40px 20px 40px;
}

#cc-language-about-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.cc-language-about-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: white;
  padding: 10px;
  margin: 5px;
}

#cc-carter-headshot {
  width: 450px;
  height: 450px;
  position: relative;
  overflow: hidden;
  transform-origin: top left;
  border: 5px solid white;
}

#cc-carter-headshot::before {
  content: "";
  transform: skewX(6deg) skewY(6deg);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  position: absolute;
  transform-origin: top left;
  left: -75px;
  top: -45px;
  width: 550px;
  height: 550px;
}

#cc-works-container {
  background-color: rgb(30, 30, 30);
  padding: 50px 20px 50px 20px;
}

#cc-repo-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 150px;
}

.cc-github-card {
  border-radius: 15px;
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.2);
  padding: 10px;
  min-height: 220px;
  max-height: 220px;
  min-width: 360px;
  max-width: 360px;
  margin: 10px;
  transition: all 125ms;
  background: white;
  /* opacity: 0.8; */
}

.cc-github-card:hover {
  transform: translateY(-6px) scale(1.01);
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 128, 0, 0.6);
}

.cc-language-icon {
  font-size: 20px;
}

#cc-js {
  background: black;
}

#cc-ts {
  background: white;
}

#cc-csharp {
  font-size: 22px;
  border-radius: 50%;
}

.cc-repo-link {
  color: black;
  text-decoration: none;
}

.cc-repo-link:hover {
  color: rgb(75, 75, 75);
}

.cc-repo-title {
  margin: unset;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cc-language {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
}

.cc-repo-topics {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.cc-repo-topic {
  background: lightgray;
  border-radius: 5px;
  padding: 5px;
  margin: 3px;
}

.cc-header {
  color: green;
}

#cc-website-bar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 60px;
}

.cc-website-item-link {
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  color: black;
}

.cc-website-item-link:hover {
  transform: scale(0.95);
}

.cc-website-item {
  background: rgb(30, 30, 30);
  border-radius: 50%;
  height: 150px;
  width: 150px;
  margin: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cc-website-item img {
  width: 100px;
  height: auto;
}

section {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
}

section .cc-sec1 {
  background: #fff;
  width: 100%;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 100px;
}

.cc-skewed1 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left-width: 100vw;
  border-top-width: 100px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: transparent transparent transparent rgb(30, 30, 30);
  border-style: solid;
  z-index: 10;
}

.cc-skewed2 {
  position: absolute;
  left: 0;
  top: 0;
  border-left-width: 100vw;
  border-top-width: 100px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: rgb(30, 30, 30) transparent transparent transparent;
  border-style: solid;
  z-index: 10;
}

#cc-home-contact-container {
  min-height: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#portfolio {
  font-size: xx-large;
  margin-bottom: 0;
}

#cc-company-bar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.cc-company-item-link {
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  color: black;
}

.cc-company-item-link:hover {
  transform: scale(0.95);
}

.cc-company-item {
  background: white;
  border-radius: 50%;
  border: 2px solid rgb(30, 30, 30);
  height: 150px;
  width: 150px;
  margin: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cc-company-item img {
  width: 100px;
  height: auto;
}

.cc-company-item img:hover {
  cursor: pointer;
}

@media (max-width: 375px) {
  #cc-home-container {
    margin-top: unset;
  }

  .cc-skewed1 {
    border-top-width: 25px;
  }

  .cc-skewed2 {
    border-top-width: 25px;
  }

  #cc-about-container {
    padding-bottom: 275px;
  }

  #cc-carter-headshot {
    transform: skewX(0deg);
    width: 150px;
    height: 150px;
  }

  #cc-carter-headshot::before {
    transform: skewX(0deg);
    left: -9px;
    top: 0px;
    width: 160px;
    height: 150px;
  }
}

@media (max-width: 550px) {
  #cc-carter-headshot {
    transform: skewX(0deg);
    width: 300px;
    height: 300px;
  }

  #cc-carter-headshot::before {
    transform: skewX(0deg);
    left: -29px;
    top: 0px;
    width: 330px;
    height: 300px;
  }
}

@media (max-width: 1000px) {
  .cc-skewed1 {
    border-top-width: 50px;
  }

  .cc-skewed2 {
    border-top-width: 50px;
  }

  #cc-about-container {
    padding-bottom: 300px;
    flex-direction: column-reverse;
    padding-top: 0px;
  }

  .cc-about-skew {
    transform: skew(0deg);
    margin-top: 150px;
  }

  #cc-carter-headshot {
    transform: skewX(0deg);
    width: 300px;
    height: 300px;
  }

  #cc-carter-headshot::before {
    transform: skewX(0deg);
    top: 0px;
    left: -29px;
    width: 330px;
    height: 300px;
  }
  
  #cc-about-details {
    padding: unset;
    transform: skew(0deg);
  }

  #cc-repo-card-container {
    justify-content: center;
  }

  #cc-website-bar {
    flex-direction: column;
    align-items: center;
  }
  
  #cc-company-bar {
    flex-direction: column;
    align-items: center;
  }

}
