@import url("https://fonts.googleapis.com/css2?family=Megrim&display=swap");

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#cc-header-container {
  background-color: rgb(30, 30, 30);
  min-height: 70px;
  max-height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 30px 10px 30px;
  box-shadow: 0 1px 10px 0px green;
  z-index: 9999;
}

#cc-name-text {
  color: white;
  float: left;
  font-family: "Megrim", cursive;
  font-size: 35px;
  margin: 18px 0px 15px 0px;
  text-align: center;
  user-select: none;
  transform: rotateZ(4deg);
}

#cc-navbar {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

#cc-mobile-nav-bar {
  display: none;
  float: right;
}

#cc-navbar h4 {
  flex: 1;
  color: white;
  padding: 15px;
  margin: 12px 0px 12px 0px;
  user-select: none;
  font-size: 20px;
}

#cc-navbar h4:hover {
  cursor: pointer;
  transform: scale(0.97);
  color: green;
  transition: all 250ms;
}

.cc-pipe {
  height: 35px;
  width: 2px;
  background: green;
}

/* MOBILE */

.mobile-nav-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: none;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.375s;
}

.mobile-logo {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1000;
}

.mobile-nav-bar.is-open {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}

.mobile-nav-bar::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -15px;
  background: rgb(30, 30, 30);
  transform-origin: 0 0;
  transform: skew(-14deg) translateX(-120%);
  transition: all 0.275s 0.1s;
}

.mobile-nav-bar.is-open::before {
  transform: skew(-14deg) translateX(0);
}

.mobile-nav-bar ul {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  transform: translateX(-18%) skew(-16deg);
}

.mobile-nav-bar li {
  display: block;
  margin: 0.5rem 0;
  text-align: right;
  transform: skew(16deg);
}

.mobile-nav-bar button {
  opacity: 0;
  transform: translateY(-10px);
}

.mobile-nav-bar.is-open button {
  opacity: 1;
  transform: translateY(0);
}

.mobile-nav-bar li:nth-child(1) button {
  transition: all 275ms 175ms;
}

.mobile-nav-bar li:nth-child(2) button {
  transition: all 275ms 225ms;
}

.mobile-nav-bar li:nth-child(3) button {
  transition: all 275ms 275ms;
}

.mobile-nav-bar li:nth-child(4) button {
  transition: all 275ms 325ms;
}

.mobile-nav-bar li:nth-child(5) button {
  transition: all 275ms 375ms;
}

.mobile-nav-bar li:nth-child(6) button {
  transition: all 275ms 425ms;
}

.mobile-nav-bar ul,
.mobile-nav-bar li {
  list-style: none;
  padding: 0;
}

.mobile-nav-bar button {
  display: block;
  padding: 8px 0;
  color: white;
  font-size: 1.4em;
  font-weight: bold;
  border: none;
  outline: none;
  background: none;
  min-width: 110px;
  border-top: solid 2px rgb(46, 46, 46);
}

.mobile-nav-bar button:hover {
  cursor: pointer;
}

.open-mobile-nav-bar {
  position: absolute;
  top: 25px;
  padding-top: 20px;
  right: 15px;
  z-index: 1000;
  background: none;
  border: 0;
  cursor: pointer;
  border: none;
}

.open-mobile-nav-bar:focus {
  outline: none;
}

.burger-menu {
  position: relative;
  display: block;
  width: 28px;
  height: 4px;
  margin: 0 auto;
  background: white;
  transition: all 0.275s;
}

.burger-menu:after,
.burger-menu:before {
  content: "";
  display: block;
  height: 100%;
  background: white;
  transition: all 0.275s;
}

.burger-menu:after {
  transform: translateY(-12px);
}

.burger-menu:before {
  transform: translateY(-16px);
}

.is-open .burger-menu {
  transform: translateY(-8px) rotate(-45deg);
}

.is-open .burger-menu:before {
  transform: rotate(90deg);
}

.is-open .burger-menu:after {
  transform: translateY(-12px);
  opacity: 0;
}

.burger-menu-text {
  font-family: "Montserrat", sans-serif;
  display: block;
  font-size: 0.675rem;
  letter-spacing: 0.05em;
  margin-top: 0.5em;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: white;
}

.device {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100vw;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.device.is-open {
  pointer-events: all;
}

.device-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

#cc-mobile-nav {
  display: none;
  float: right;
}

@media (max-width: 795px) {
  #cc-navbar {
    display: none;
  }
  #cc-mobile-nav {
    display: block;
  }
  .device {
    display: block;
  }
}

@media (max-width: 375px) {
  #cc-name-text {
    font-size: 25px;
  }

  #cc-header-container {
    position: relative;
    max-height: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
