@keyframes bounceUp {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

#cc-footer-container {
  background: rgb(30, 30, 30);
  height: 500px;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

#cc-footer-top {
  background: rgb(60, 60, 60);
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  text-align: center;
  padding: 10px 0px;
}

#cc-footer-top h3 {
  margin: unset;
  font-weight: 300;
}

#cc-footer-top h3:hover {
  cursor: pointer;
  color: green;
}

.cc-top-arrow {
  animation: bounceUp 1.75s infinite ease-in-out;
}

#cc-footer-copyright {
  font-weight: 200;
  font-size: small;
  text-align: center;
}

.spacer {
  flex-grow: 1;
}

#cc-contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.cc-contact-a {
  margin: 20px;
  text-decoration: none;
}

.cc-contact-text {
  height: auto;
  margin: unset;
}

.cc-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 550px) {
  #cc-contact-container {
    flex-direction: column;
  }
  #cc-footer-container {
    padding-top: 40px;
    height: auto;
  }
}
