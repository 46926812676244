@keyframes scaling {
  0% {
    width: 0;
  }
  50% {
    width: 25%;
  }
  100% {
    width: 0;
  }
}

@keyframes moveTo {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(150%);
  }
}

#cc-main-loading-container {
  background: rgb(25, 25, 25);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

#cc-load-container {
  transform: rotateZ(45deg);
  width: 50%;
  height: 50%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cc-load-container > div:nth-of-type(1) {
  top: 30%;
  left: 25%;
  animation-delay: 0s;
}

#cc-load-container > div:nth-of-type(2) {
  top: 10%;
  left: 0%;
  animation-delay: 0.8s;
}

#cc-load-container > div:nth-of-type(3) {
  top: 15%;
  left: 10%;
  animation-delay: 0.5s;
}

#cc-load-container > div:nth-of-type(4) {
  top: 25%;
  left: 30%;
  animation-delay: 1.6s;
}

#cc-load-container > div:nth-of-type(5) {
  top: 40%;
  left: 4%;
  animation-delay: 3.2s;
}

#cc-load-container > div:nth-of-type(6) {
  top: 55%;
  left: 18%;
  animation-delay: 1.2s;
}

#cc-load-container > div:nth-of-type(7) {
  top: 66%;
  left: 3%;
  animation-delay: 0.4s;
}

#cc-load-container > div:nth-of-type(8) {
  top: 77%;
  left: 24%;
  animation-delay: 2s;
}

#cc-load-container > div:nth-of-type(9) {
  top: 83%;
  left: 30%;
  animation-delay: 1s;
}

#cc-load-container > div:nth-of-type(10) {
  top: 63%;
  left: 45%;
  animation-delay: 0.8s;
}

.cc-loading-star {
  height: 2px;
  background: linear-gradient(-45deg, green, rgba(0, 0, 255, 0));
  position: absolute;
  border-radius: 50%;
  animation: scaling 5s ease-in-out infinite, moveTo 5s ease-in-out infinite;
}

#cc-loading-text-container {
  transform: rotateZ(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#cc-loading-text {
  text-align: center;
  color: white;
  letter-spacing: 2px;
}

#cc-loading-info {
  text-align: center;
  color: white;
}
